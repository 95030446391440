export default [
    { time: 0, text: "Welcome to the user manual of Prepworld. " },
    { time: 2, text: "For your information this video will include three sections. " },
    { time: 6, text: "After we sign in, as you can see we have a couple of buttons here. For this section we will start with the manage users and access rights and come back to the rest of them in the other sections." },
    { time: 16, text: "When we click on this, we have manage roles, manage users and update role permissions and update user role. In the manage role section we have the roles of the team members. " },
    { time: 32, text: "We click here to create a new role and we click anywhere here if we want to update or remove a role." },
    { time: 39, text: "In <b> Manage Users</b>, we have all the users entering the tool. For this section we use the same logic. We click here to create a new user or we can click anywhere here to update or remove a user. " },
    { time: 55, text: "In <b> Update Role Permissions </b>, we can arrange what the roles can do. I can take any of these and then I click on the checkboxes here to make changes. When I click on update it will save the changes. " },
    { time: 70, text: "In the  <b> Update User Role </b> section we assign the roles to the users. I can pick anyone here and again when I click on the checkboxes it will make changes and when I click on update it will save the changes. " },
    { time: 83, text: "And this is everything for the manage users and user access right section." },
    

    { time: 89, text: "Hello everyone. For this section we look at <b> Manage Default Inputs </b> and <b> Manage Production Plan.  </b>" },
    { time: 95, text: "Before we create a production plan we need to set up <b> Manage Default Inputs </b>. Just to keep in mind that manage default inputs don't represent the production plan and the user need to set the default input before creating the plan. " },
    { time: 107, text: "Here when I click, we have the values create new values and update existing values. For example if I want to create tomorrow's project plan, for that I need all these inputs on this section: customer name, customer lorry allocation, break times etc. " },
    { time: 127, text: "If I want to update existing values, I come here and for example in the break times, I click on here and if I want to make changes, I make the changes and I click on update or if I want to remove anything again I remove it and then click on remove. " },
    { time: 147, text: "After I set all these default inputs, we go to  <b> Manage Production Plan </b> . We click on choose date, and here we have the production plans of each date that was created. For example one for May 12th and for the 13th I click on here and pick a date and click on create. " },
    { time: 172, text: "I will not click the button right now, but when we create a 13th all the inputs from defaults appear automatically. So we export them once it is created. " },
    { time: 186, text: "If want to go to a specific date I click here and then I click on go. After I click on go, we have this section here. If I want to import a new value I click on this you can <b> Import a Forecast or an Order </b>. If I click on update existing values that lets us update the inputs and the production plan. " },
    { time: 208, text: "For example, I can pick hygiene and I can check what was put here before. These all came from the default input automatically and I can update them from here if needed I just change it. " },
    { time: 225, text: "To create new values on this and enter the info as needed. As you can see we have the hygiene here automatically. To put a new hygiene time for example I can click insert new values. " },
    { time: 239, text: "Just to note something about  <b>Production Plan Date </b>, for example if it says 17th of May here, it means that it started on the 16th and ended on the 17th. We can click on shifts here and as you can see it started on the 16th and ended on the 17th. " },
    { time: 261, text: "In the <b>Check Production Inputs</b> and apply default values we have these options: You can check the production input, check the general system, or apply to values. if you click on default values here we have these headings we use this button if the production plan has already been created and we want to make changes to that day only. " },
    { time: 283, text: "We can take which one we want to change and then click on go and save the changes. And that's everything we have for this section." },


    { time: 293, text: "Hello everyone, in the third and the last section we’ll look at everything else that was left out. " },
    { time: 298, text: "I’m currently in the <b> Manage Production Plan</b> tab." },
    { time: 301, text: "As discussed in the previous sections, we added our default inputs from here, after that we can click on <b> Check Production Inputs </b> and <b> Check General Systems </b> to make sure that there aren't any errors. " },
    { time: 312, text: "Once we're sure that there aren't any errors we go to run production plan. In the run production plan section on the left side we have all our lines. " },
    { time: 322, text: "The number in the bracket shows us how many SKU codes are planned for that shift. As you can see we have 15 here and some of them are red. " },
    { time: 331, text: "The red ones don't have a start and end time as you can see. This shows that until there, we were able to complete our SKU codes during the shift and there is not enough time for these ones that's why they're showing in red. " },
    { time: 345, text: "If I want to add another SKU code here, I pick one of the unallocated SKU codes from the section and I click on <b> Insert the Selected Line </b>. Now my SKU code is shown on the left side and as you can see on allocated SKU, called number has changed. " },
    { time: 383, text: "Here we also have the  <b> Change Order </b> button if I want to change the order of the SKU codes, I can make the order however I like it and then I click on change order. " },
    { time: 421, text: "Also, if I want to remove any of the SKU codes here from this line, I click on the one I'd like to remove and then I click on  <b> Unallocate </b>. And as you can see, it has been sent back to the unallocated SKU codes section and the number here has changed. " },
    { time: 459, text: "We also have the <b> Display Availability  </b> button. When I click on this it shows me how much time left for each line. And we have the export allocation button here. This button allows us export SKU allocations to our current production plan from the previous days. " },
    { time: 492, text: "For example, let's say I'm creating the production plan for the 21st. If I click on the 20th of May here and click on export, I can import the SKU allocation from the day to the 21st." },
    { time: 512, text: "After I'm done with the plan, I can click on preview production plan. I can review everything here, and when I’m happy I can click on <b> Ready, Go </b>. Our production plan is now ready for that date. " },
    { time: 581, text: "We have the <b> Summary </b> section now. Here there are summaries about the SKU codes. For example, how many of them are planned and how many of them are packed etc." },
    { time: 592, text: "When I scroll down on the section we have a SKU code table and we can see all the information here. In the  <b> Packed Count</b>, we see how many SKU codes are packed here. when I change some of the details here or put more information and after I click on update, the production plan will be calculated automatically. " },
    { time: 614, text: "In the <b> References</b> section, we show the inputs, shifts, break times, hygiene times etc. We have all the inputs here. " },
    { time: 626, text: "In <b> Productivity</b>,  we calculate productivity. We give this information for analysing. For example you can check the shifts, required KG, length of shifts and all the details and the manager can look at the details from here for detail calculations. " },
    { time: 643, text: "Then we have the <b> Assembly Plan</b>. We already looked at which SKU codes to be preceded at which line and this is all the assembly plan. As you can see, we have the SKU codes here and the lines. " },
    { time: 656, text: "From this Assembly plan, we create an <b> Issuing Plan</b>. This will show us the details of the SKU codes with the fruits so when we look at here, we see the total KG and we see which fruits are included in that. " },
    { time: 671, text: "<b> Combine lines </b> are for printing. Based on the assembly plan we create the combined line section and this is to be printed out. " },
    { time: 684, text: "Here, we have the WIP targets. We have five of these tables. If the user wants, they can enter a KG here for example and click on update. And after we click update, it will automatically update in the background. " },
    { time: 703, text: "The logic is the same for each of them. We can make changes here and then click on update. " },
    { time: 716, text: "Based on the Issuing plan, we create the <b> Hourly Recipe Prep Plan </b> and show everything in detail. Then, we create the  <b> Recipe</b>, and <b> Whole</b> sections. " },
    { time: 730, text: "This is based on the recipe, and this is based on the whole. " },
    { time: 740, text: "Now we'll go through the other buttons on the page. " },
    { time: 745, text: "<b> Hide Empty Rows and Columns </b> hides the empty lines in the tables. <b> Hide Empty WIP Tables Columns </b> hides the empty lines in the WIP tables. <b>Unhide</b> is basically unhiding. <b>Create Kg-Variance</b> automatically creates the <b>Variance Sheet</b>." },
    { time: 762, text: "<b>Email fruit sheet</b> sends the email and <b>Send Assembly plan</b> sends the Assembly plan. So, these two are basically for emailing. " },
    { time: 774, text: "The <b>Logs</b> section shows us what everyone did. If there are any issues about a specific date, we can pick a date from here and click on, and see what everyone did on that date. This keeps a log of what every user did step by step. You can put filters here if needed and we can clear the filter at the end." },
    { time: 800, text: "And we can click here if you want to log out and this is everything we have for the user manual." },


    // Add more entries as needed






  ];
<template>
    <div
      id="knowledge-base-article"
    >
      <b-row>
  
        <!-- related question -->
        <!-- <b-col
          lg="3"
          md="5"
          order="2"
          order-md="1"
        >
          <b-card> -->
            <!-- <h6 class="kb-title">
              <feather-icon
                icon="InfoIcon"
                size="20"
                class="mr-50"
              />
              Related Questions
            </h6> -->
            <!-- <b-list-group class="list-group-circle mt-1">
              <b-list-group-item
                v-for="(que,index) in kb_question.relatedQuestions"
                :key="index"
                href="javascript:void(0)"
                class="text-body"
              >
                {{ que.question }}
              </b-list-group-item>
            </b-list-group> -->
        
<!-- 
          </b-card>
        </b-col> -->
        <!--/ related question -->
  
        <b-col
          lg="12"
          md="12"
          order="1"
          order-md="2"
        >
        
          <b-card class="p-5">
  
            <!-- Title -->

            <b-row>


           
  
            <!-- Content -->
            <!-- eslint-disable vue/no-v-html -->
                <b-col md="2">
                    <b-card-title class="mb-1">
                <b-row>
                    <b-col md="1">
                        <div><img src="@/assets/images/pages/prepworld.svg" height="150px" width="150px"/></div>

                    </b-col>
                    
                
                </b-row>

            
          
            </b-card-title>
           
                        <div class="mt-1">
                    <span @click="skipTo(0)" style="text-decoration: underline; cursor: pointer; "> Section 1: Manage Users and Access Rights </span>
   
                </div>
                <div class="mt-1">
                    <span @click="skipTo(89)" style="text-decoration: underline; cursor: pointer;"> Section 2: Manage Default Inputs and Production Plan </span>

                </div>
                <div class="mt-1">
                    <span @click="skipTo(293)" style="text-decoration: underline; cursor: pointer;"> Section 3: Other Settings </span>

                </div>
                    </b-col>

                    <b-col md="10">
                        <div id="video-container" class="mt-1">
                <video ref="videoManual" id="videoManual" width="1000" key="user_manual.mp4" controls @timeupdate="onTimeUpdate">
                    <source src="https://api.portal.systemstailormade.com/storage/app/public/prepworld_user_manual.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            
               
                </div>
                <div id="transcript-container">
                <div v-for="(line, index) in transcript" :key="index" class="transcript-line" >
                    <span class="time-marker" @click="skipTo(line.time)">{{ formatTime(line.time) }}</span>
                    <span v-html="line.text" :class="{ highlighted: currentLineIndex === index }"></span>
                </div>
                </div>

                    </b-col>
            </b-row>
           
            <!-- eslint-enable -->
          </b-card>
        </b-col>
      </b-row>
  
    </div>
  </template>
  
  <script>
  import {
    BRow, BCol, BCard, BListGroup, BListGroupItem, BCardText, BCardTitle,
  } from 'bootstrap-vue'
  
  import transcript from './transcript.js';

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BListGroup,
      BCardText,
      BCardTitle,
      BListGroupItem,
    },
    data() {
      return {
        kb_question: {},
        transcript,
      currentLineIndex: -1,

      }
    },
    created() {
      // ! You have to update the below API call according to route parameter
      // * We are using fixed API call for all categories for demo purposes
 
    },
    methods: {
    onTimeUpdate() {
      const currentTime = this.$refs.videoManual.currentTime;
      this.updateCurrentLine(currentTime);
    },
    updateCurrentLine(currentTime) {
      for (let i = 0; i < this.transcript.length; i++) {
        if (currentTime >= this.transcript[i].time && (!this.transcript[i + 1] || currentTime < this.transcript[i + 1].time)) {
          this.currentLineIndex = i;
          break;
        }
      }
    },
    skipTo(seconds) {
            var video = document.getElementById('videoManual');
            video.currentTime = seconds;
            this.$refs.videoManual.play();
            
        },
        formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
  }
    }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-knowledge-base.scss';
  </style>
<style>
#video-container {
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

#transcript-container {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.transcript-line {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.transcript-line:hover .time-marker {
  text-decoration: underline;
}

.time-marker {
  margin-right: 10px;
  color: gray;
}

.highlighted {
  background-color: #f9f001;
}
</style>
